import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import get from "lodash/get";
import { error as errorToast } from "utils/toast";

export const useErrorHandler = (errorSetter?: (err: string) => void) => {
  const { t } = useTranslation("systemFeedback");
  const [error, setError] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");

  const handleError = ({
    err,
    showToast = true,
  }: {
    err: any;
    showToast?: boolean;
  }) => {
    const responseErrorCode = get(err, "response.data.errorCode", "");
    let tempErrorCode = "";

    const payload = get(err, "response.data.payload", {});
    let errorMessage = "";
    if (responseErrorCode) {
      errorMessage = t(responseErrorCode, payload);
      tempErrorCode = responseErrorCode;
    } else {
      tempErrorCode =
        err?.response?.data?.error ||
        err?.response?.data?.message ||
        err?.response?.data?.name ||
        err?.code ||
        err?.message ||
        err?.errorCode ||
        err ||
        "something_went_wrong";
      errorMessage = t(tempErrorCode);
    }

    setErrorCode(tempErrorCode);
    setError(errorMessage);
    if (
      showToast &&
      tempErrorCode !== "auth/user-not-found" &&
      tempErrorCode !== "auth/wrong-password" &&
      tempErrorCode !== "auth/too-many-requests"
    ) {
      errorToast({ message: errorMessage });
    }
  };

  const resetError = () => setError("");

  const onError = (err: any) => handleError({ err });

  useEffect(() => {
    if (error && errorSetter) {
      errorSetter(error);
    }
    return () => resetError();
  }, [error, errorSetter]);

  return { error, handleError, resetError, onError, errorCode };
};
