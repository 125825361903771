import BusinessComponentsApi from "api/BusinessComponentsApi";
import ProjectsApi from "api/ProjectsApi";
import ViewsApi from "api/ViewsApi";
import TagsApi from "api/TagsApi";
import TeamsApi from "api/TeamsApi";
import GlobalApi from "api/GlobalApi";
import TemplatesApi from "api/TemplatesApi";
import UsersApi from "api/UsersApi";
import BillingApi from "api/BillingApi";
import { createContext, useContext } from "react";

import { useUser } from "./UserContext";
import { useTeam } from "./TeamContext";
import MonitorApi from "api/MonitorApi";
import TvChannelsApi from "api/TvChannelsApi";
import WorkersApi from "api/WorkersApi";
import TrendDashboardApi from "api/TrendDashboardApi";
import AdminApi from "api/AdminApi";
import AIDesignProjectApi from "api/AIDesignProjectApi";
import AIDesignAssetsApi from "api/AIDesignAssetsApi";
import ProductApi from "api/ProductApi";
import ImageGenerationApi from "api/ImageGenerationApi";
import CommentsApi from "api/CommentsApi";
import PortalApi from "api/PortalApi";
import { usePortal } from "./PortalContext";
import BenchmarkApi from "api/BenchmarkApi";
import BenchmarkGlobalApi from "api/BenchmarkGlobalApi";
import { useQueryClient } from "react-query";
import RoomsApi from "api/RoomsApi";
import ArticlesApi from "api/ArticlesApi";
import AssetFoldersApi from "api/AssetFoldersApi";
import { ShopifyGlobalApi } from "api/ShopifyApi";
import DomainApi from "api/DomainApi";
import ProductOrdersApi from "api/ProductOrdersApi";
import SupplierApi from "api/SupplierApi";
import NotificationsApi from "api/NotificationsApi";
import ReportsAPI from "api/ReportsAPI";
import AuthActivityLogsApi from "../api/AuthActivityLogsApi";

export interface ApiContext {
  bc: BusinessComponentsApi;
  projects: ProjectsApi;
  views: ViewsApi;
  users: UsersApi;
  templates: TemplatesApi;
  tags: TagsApi;
  teams: TeamsApi;
  global: GlobalApi;
  billing: BillingApi;
  monitor: MonitorApi;
  tvChannels: TvChannelsApi;
  workers: WorkersApi;
  trendDashboard: TrendDashboardApi;
  aiDesignProject: AIDesignProjectApi;
  aiDesignAssets: AIDesignAssetsApi;
  admin: AdminApi;
  products: ProductApi;
  imageGeneration: ImageGenerationApi;
  comments: CommentsApi;
  portal: PortalApi;
  benchmarkApi: BenchmarkApi;
  benchmarkGlobalApi: BenchmarkGlobalApi;
  rooms: RoomsApi;
  authActivityLogs: AuthActivityLogsApi;
  articles: ArticlesApi;
  assetFolder: AssetFoldersApi;
  shopifyGlobalApi: ShopifyGlobalApi;
  domain: DomainApi;
  productOrders: ProductOrdersApi;
  supplier: SupplierApi;
  notifications: NotificationsApi;
  reports: ReportsAPI;
}

const ApiContext = createContext<ApiContext | undefined>(undefined);

export const ApiProvider = ({ children }: any) => {
  const { userToken, auth } = useUser();
  const { teamId: currentTeamId } = useTeam();
  const { userPortal } = usePortal();
  const queryClient = useQueryClient();

  const context = {
    bc: new BusinessComponentsApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    projects: new ProjectsApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    views: new ViewsApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    users: new UsersApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    templates: new TemplatesApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    tags: new TagsApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    teams: new TeamsApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    global: new GlobalApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    billing: new BillingApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    monitor: new MonitorApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    tvChannels: new TvChannelsApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    workers: new WorkersApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    trendDashboard: new TrendDashboardApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    aiDesignProject: new AIDesignProjectApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    aiDesignAssets: new AIDesignAssetsApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    admin: new AdminApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    products: new ProductApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    imageGeneration: new ImageGenerationApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    comments: new CommentsApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    portal: new PortalApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    benchmarkApi: new BenchmarkApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    benchmarkGlobalApi: new BenchmarkGlobalApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    rooms: new RoomsApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    authActivityLogs: new AuthActivityLogsApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    articles: new ArticlesApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    assetFolder: new AssetFoldersApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),

    shopifyGlobalApi: new ShopifyGlobalApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    domain: new DomainApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    productOrders: new ProductOrdersApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    supplier: new SupplierApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    notifications: new NotificationsApi(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
    reports: new ReportsAPI(
      userToken,
      currentTeamId,
      auth,
      queryClient,
      userPortal?.id
    ),
  };

  return <ApiContext.Provider value={context}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("no api context provided");
  }
  return context;
};
