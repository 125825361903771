import { QueryClient } from "react-query";
import { AuthActivityLogStatuses, AuthActivityLogTypes } from "types";
import AbstractApi from "./AbstractApi";

export interface ICreateAuthActivityLogPayload {
  clientDeviceId: string | null;
  email: string;
  type: AuthActivityLogTypes;
  status: AuthActivityLogStatuses;
  portalId?: number;
  locale?: string;
  registrationSource?: string;
  registrationPromoCode?: string | null;
  signUpCode?: string | null;
  error?: string | null;
}

export default class AuthActivityLogsApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async createAuthActivityLog(payload: ICreateAuthActivityLogPayload) {
    console.log({
      payload,
    });

    return await this.client.post("/auth-activity-logs", payload);
  }
}
