import { AIDesignAssetType } from "api/AIDesignAssetsApi";
import { BenchmarkProjectType } from "api/BenchmarkApi";
import { PaymentPreview } from "api/BillingApi";
import { IBrowserProps } from "contexts/ProductBrowserContext";
import { IUserInfo, UserModel } from "contexts/UserContext";
import BillingHistory from "models/BillingHistory";
import Project from "models/Project";
import Team, { UserTeam } from "models/Team";
import { TVGarment } from "models/TvGarment";
import { Event } from "react-big-calendar";
import Stripe from "stripe";
import { Gender } from "utils/constants";

export type QueryMode = "preview" | "onetime";

export interface IBusinessComponent {
  filters: Record<string, any>;
  setFilters: any;
  setImmediateFilterState: any;

  filtersAdditionalProps: Record<string, Record<string, any>>;
  setFiltersAdditionalProps: any;

  channel: string;

  mode?: QueryMode;

  openProductBrowser: (val: IBrowserProps) => void;
  openSingleProductBrowser: (val: IBrowserProps) => void;
}

export interface IBusinessComponentStatic {
  previewProps: {
    filters: any;
  };
  dataChannels: DataChannelType[];
}

export interface IBCFilterProps {
  field: string;

  filters: Record<string, any>;
  setFilters: any;

  filtersAdditionalProps: Record<string, Record<string, any>>;
  setFiltersAdditionalProps: any;

  name: string;
  dataTip?: string;

  onChange: any;
  disabled?: boolean;
}

export interface IBCFilter {
  props: any;
  name: string;
  component: any;
  field: string;
}

export interface IBillingHistoryResponse {
  data: BillingHistory[];
  hasMore: boolean;
}

export interface IBillingHistoryInvoiceItem {
  amount: number;
  description: string;
  unitAmount: number;
  quantity: number;
}

export interface IBillingHistoryInvoiceResponse {
  total: number;
  paid: number;
  items: Array<IBillingHistoryInvoiceItem>;
}

export const organizationType = {
  FASHION: "fashion",
  CONSULTANCY: "consultancy",
  MEDIA: "media",
  IT: "it",
  EDUCATION: "education",
  OTHERS: "others",
};

export const jobOptions = {
  DESIGN: "design",
  MARKETING: "marketing",
  MERCHANDISING: "merchandising",
  PRODUCT: "product_dev",
  RETAIL: "retail_buying",
  ECOMMERCE: "ecommerce",
  OTHER: "other",
};

export interface IPaginateParams {
  [key: string]: string | number;
}

export interface BusinessComponentElement {
  displayName: string;
  filters?: IBCFilter;
  hasMonitoring: boolean;
}

export enum AppRoleEnum {
  "admin" = 1,
  "user" = 2,
}

export enum GuestRoleEnum {
  "guest" = 1,
  // "editor" = 2,
}

export enum TeamRoleEnum {
  "owner" = 1,
  "editor" = 2,
  "viewer" = 3,
  "guest" = 4,
  "admin" = 5,
}

export enum MonitoringFrequency {
  WEEK = "weekly",
  MONTH = "monthly",
}

export const similarBrandsSort = {
  AMOUNT_ASC: "amount_0_9",
  AMOUNT_DESC: "amount_9_0",
  NAME_ASC: "name_a_z",
  NAME_DESC: "name_z_a",
};

export const DataChannels = {
  overview: "overview",
  retail: "retail_marketplace",
  d2c: "brand_site",
  digital: "digital_fashion",
  resale: "resale_marketplace",
  social: "social_search",
  retailer_kr: "retailer_kr",
  outlet: "outlet_us",
};

export enum FeatureNames {
  TEXT_TO_IMAGE = "textToImage",
  IMAGE_TO_IMAGE = "imageToImage",
  UPSCALING = "upscaling",
  MASKING = "masking",
  IN_PAINTING = "inPainting",
}

export enum PlanNames {
  BASIC = "basic",
  PREMIUM = "premium",
  ENTERPRISE = "enterprise",
  NOPLAN = "no-plan",
}

export enum NewsletterPlanNames {
  BRAND_FREE = "brand/free",
  BRAND_PRO = "brand/pro",
  BRAND_ENTERPRISE = "brand/enterprise",
  SUPPLIER_FREE = "supplier/free",
  SUPPLIER_ENTERPISE = "supplier/enterprise",
}

export enum NewsletterDeliveryStatus {
  DELIVERED = "delivered",
  FAILED = "failed",
  OPENED = "opened",
  CLICKED = "clicked",
  UNSUBSCRIBED = "unsubscribed",
}

export enum NewsletterDeliveryFrequency {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

export enum PlanOfferStatuses {
  ACTIVE = "active",
  INACTIVE = "inactive",
  USED = "used",
}

export enum AuthActivityLogStatuses {
  SUCCESS = "success",
  FAILED = "failed",
}

export enum AuthActivityLogTypes {
  LOGIN_PASSWORD = "login-password",
  LOGIN_GOOGLE = "login-google",
  REGISTRATION = "registration",
  FORGOT_PASSWORD = "forgot-password",
}

export interface ISortOption {
  name: string;
  value: {
    field: string;
    direction: string;
  };
  sectionSplitter: (val: any) => void;
}

export type PaginatedResponse<T> = {
  data: T[];
  links: {
    current: string;
    last: string;
    next: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
};

export interface IFacetData {
  min_g_F: number;
  max_g_F: number;
  min_g_M: number;
  max_g_M: number;
  min_g_U: number;
  max_g_U: number;
  min_cg_C: number;
  max_cg_C: number;
  min_cg_F: number;
  max_cg_F: number;
  min_cg_B: number;
  max_cg_B: number;
  min_cg_Y: number;
  max_cg_Y: number;
  min_cg_O: number;
  max_cg_O: number;
  min_t_ig_rank: number;
  max_t_ig_rank: number;
  min_num_categories: number;
  max_num_categories: number;
  min_num_ptype: number;
  max_num_ptype: number;
  min_num_items: number;
  max_num_items: number;
  min_num_retailers: number;
  max_num_retailers: number;
  num_rows: number;
}

export interface ISources {
  m: number | null;
  d2c: number | null;
  dg: number | null;
  r: number | null;
}

export interface IBrand {
  brand: string;
  sources: ISources;
}

export interface IFacets {
  multibrand: IFacetData[];
  brand_d2c: IFacetData[];
  digital: IFacetData[];
  search_social: IFacetData[];
}

export type FacetsResponse<T> = {
  facets: IFacets;
  rows: T[];
};

export type DataChannelType = keyof typeof DataChannels;

export type ProductType = {
  available: number;
  basecat: string;
  brand: string;
  bucket_price_usd: number;
  catgroup: string;
  color: string;
  fabric: string;
  colormain: string;
  colorvendor: string;
  currency: string;
  display_rank: number;
  first_seen: string;
  gender: Gender;
  geosource: string | null;
  hasmarkdown: number;
  haspromo: number;
  jbid: string;
  material: {
    mat: Array<{
      comp: {
        [key: string]: {
          v: number;
          t: any[];
        };
      };
      l: string[];
    }>;
  } | null;
  name: string;
  num_img: number;
  pid: string;
  price: number;
  price_og: number;
  price_og_usd: number;
  price_usd: number;
  product_url: string;
  ptype: string;
  retailer: string;
  size: string | null;
  sku: string;
  skuvendor: string;
  stags: {
    stags: any[];
    lowestAsk: number;
    lowestAskDate: string;
  };
  subcat: string;
  restocks_2w?: number;
  restocks_4w?: number;
  restocks_6w?: number;
  restocks_8w?: number;
};

export type Products = ProductType[];

export type BrandFacetMenuKey =
  | "basecat"
  | "catgroup"
  | "color"
  | "colormain"
  | "fabric"
  | "gender"
  | "ptype"
  | "subcat"
  | "retailer";

export interface IBrandFacetMenuItem extends Record<BrandFacetMenuKey, string> {
  bucket_price_usd: {
    100: number;
    300: number;
    500: number;
    1000: number;
    1500: number;
    3000: number;
    10000: number;
    100000: number;
    1000000: number;
    None: number;
  };
  cnt: number;
  max_price_usd: number;
  min_price_usd: number;
  orderidx: number;
}

export type BrandFacetMenu = {
  [K in BrandFacetMenuKey]: Array<IBrandFacetMenuItem>;
};

type BrowserFilterValueType = string | string[] | number | number[];

export interface ICategory {
  basecat: string;
  sources: ISources;
}

export interface ITutorialControls {
  runTutorial: () => void;
  stopTutorial: () => void;
  run: boolean;
}

export interface IBrowserFilters {
  brand?: BrowserFilterValueType;
  jbid?: BrowserFilterValueType;
  multibrand?: BrowserFilterValueType;
  retailer?: BrowserFilterValueType;
  basecat?: BrowserFilterValueType;
  colormain?: BrowserFilterValueType;
  subcat?: BrowserFilterValueType;
  ptype?: BrowserFilterValueType;
  gender?: BrowserFilterValueType;
  fabric?: BrowserFilterValueType;
  price_usd_gt?: BrowserFilterValueType;
  price_usd_lt?: BrowserFilterValueType;
  hasmarkdown?: BrowserFilterValueType;
  catalog_name?: BrowserFilterValueType;
  theme_json?: BrowserFilterValueType;
  sortkey?: string;
}

export type ProductBrowserOpenFunction = (val: IBrowserProps) => void;

export enum PriceDateRange {
  WEEK = "week",
  TWO_WEEK = "twoWeek",
  MONTH = "month",
  TWO_MONTH = "twoMonth",
  YEAR = "year",
}

export type ProductPriceItem = {
  brand: string;
  currency: string;
  jbid: string;
  price: number;
  price_usd: number | null;
  primarycurr: number;
  updated: number;
};

export type GuestGeneralProject = {
  guestRole: GuestRoleEnum;
  id: number;
  invite: string;
  inviteVisited: boolean;
  userId: number;
};

export type GuestProject = GuestGeneralProject & {
  project: Project;
  projectId: number;
};

export type GuestTvProject = GuestGeneralProject & {
  tvProject: Project;
  tvProjectId: number;
};

export type GuestBenchmarkProject = GuestGeneralProject & {
  benchmarkProject: BenchmarkProjectType;
  benchmarkProjectId: number;
};

export type TeamMember = UserModel & {
  teams: Array<Team & { teamId: number }>;
  guestProjects: GuestProject[];
  guestTVProjects: GuestTvProject[];
  guestBenchmarkProjects: GuestBenchmarkProject[];
  teamRole: TeamRoleEnum;
};

export type OwnerTeamMember = {
  defaultTeam: boolean;
  email: string;
  id: number;
  newTeam: boolean;
  teamId: number;
  teamRole: TeamRoleEnum;
  userId: number;
};

export interface IComparisonPayload {
  brands: string[];
  channel: string;
  catgroup?: string;
  basecat?: string;
  gender?: string;
}

export enum GenerationStatus {
  NEW,
  IN_PROGRESS,
  GENERATED,
  FAILED,
}

export interface IStyle {
  style: string;
  value: string;
}

export interface ISelectValue {
  name: string;
  icon?: any;
  value?: any;
  disabled?: boolean;
  rightIcon?: any;
  tip?: string;
  preselected?: boolean;
}

export type SelectOptionsType = ISelectValue[];

export interface IActionSelectValue {
  name: string;
  onClick?: Function;
}

export type ActionSelectOptionsType = IActionSelectValue[];

export type Renderer<T> = (item: T, index: number) => JSX.Element;

export type TrendType = {
  [key: string]: {
    bgColor: string;
    value: string;
    fullName: string;
  };
};

export interface UserType {
  fullName: string;
  email: string;
  image: string;
  role: string;
}

export type Retailer = {
  id: string;
  name: string;
  source_type: string | null;
  shopUrl: string;
};

export type UsersType = UserType[];

export interface SelectOption {
  name: string;
  value: string;
}

export interface KeywordType {
  style: string;
  value: string;
  origin: string;
}

export interface RangeType {
  min: number;
  max: number;
}

export enum AIWarmingScheduleType {
  ONE_TIME = "one-time",
  REPEATABLE = "repeatable",
}

export enum Days {
  MONDAY = "monday",
  TUESDAY = "tuesday",
  THURSDAY = "thursday",
  WEDNESDAY = "wednesday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
  SUNDAY = "sunday",
}

export interface IAIWarmingSchedulerResource {
  id: number;
  timeFrom: string;
  timeTo: string;
  day: string;
  type: AIWarmingScheduleType;
  days: string[] | null;
}

export type AIWarmingScheduler = Omit<Event, "resource"> & {
  resource: IAIWarmingSchedulerResource;
};

export interface IOrderResource {
  asset: AIDesignAssetType | null;
  assetId: number | null;
  file: { url: string } | null;
  id: number;
  orderId: number;
  portalId: number | null;
  tvGarment: TVGarment | null;
  tvGarmentId: number | null;
  type: string;
}

export enum RoomType {
  DIRECT = "direct",
  GROUP = "group",
}

export enum RoomEditType {
  AddPeople = "add_people",
  EditTtile = "edit_title",
}

export enum MessageAttachedElementsType {
  ORDER = "order",
  ORDER_REQUEST = "order-request",
  ORDER_REQUIREMENT = "order-requirement",
  ORDER_PROPOSAL = "order-proposal",
}

export type TMessageAttachedElementOrder = {
  type: MessageAttachedElementsType.ORDER;
  orderId: number;
};

export type TMessageAttachedElementOrderRequest = {
  type: MessageAttachedElementsType.ORDER_REQUEST;
  orderRequestId: number;
};

export type TMessageAttachedElementOrderRequirement = {
  type: MessageAttachedElementsType.ORDER_REQUIREMENT;
  orderId?: number;
  orderRequestId?: number;
  orderRequirementId: number;
};

export type TMessageAttachedElement =
  | TMessageAttachedElementOrder
  | TMessageAttachedElementOrderRequest
  | TMessageAttachedElementOrderRequirement;

export enum Sizes {
  XXS = "xxs",
  XS = "xs",
  S = "s",
  M = "m",
  L = "l",
  XL = "xl",
  XXL = "xxl",
}

export interface ISize {
  size: string;
  quantity: number;
}

export enum OrderStatuses {
  DRAFT = "draft",
  PUBLIC = "public",
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
  PAYMENT = "payment",
  DELIVERY = "delivery",
  SHIPPED = "shipped",
  PRODUCTION = "production",
  COMPLETED = "completed",
  PENDING_PROPOSAL = "pending proposal",
  PENDING_APPROVAL = "pending approval",
}

export interface ITimezone {
  value: string;
  abbr: string;
  offset: number;
  isdst: boolean;
  text: string;
  utc: string[];
}

export enum SubscriptionStatuses {
  ACTIVE = "active",
  PAST_DUE = "past_due",
  UNPAID = "unpaid",
  TRIALING = "trialing",
}

export interface IUserOrdersStatistic {
  activeOrderRequestsCount: number;
  completedOrderRequestsCount: number;
  ordersCount: number;
}

export interface IPrice {
  quantity: string;
  price: string;
  leadTime: string;
}

export type AppEnvType = {
  createdat: string;
  id: number;
  key: string;
  updatedat: string;
  value: string;
};

export type BillingDebtsType = {
  openInvoices: Array<{
    invoice: Stripe.Invoice;
    invoicePreview: PaymentPreview;
  }>;
  upcomingInvoice: Stripe.Invoice;
  upcomingInvoicePreview: PaymentPreview;
};

export enum ArticleStatuses {
  DRAFT = "draft",
  PUBLISHED = "published",
}

export enum ModelAvailabilityStatus {
  AVAILABLE = "available",
  UNAVAILABLE = "unavailable",
  NA = "missing-data",
}

export type AiProvidersStatus = {
  lastImageGeneratedAt: string;
  model: string;
  provider: string;
  status: {
    setAt: string;
    status: string;
    availability: ModelAvailabilityStatus;
  };
  v: string;
  version: string;
  deployment: string;
};

export interface ITeamStats extends Team {
  targetUserInvitedByEmail: string | null;
  guestsCount: number;
  targetUserInvitedById: number | null;
  targetUserTeamRole: TeamRoleEnum;
  targetUserInviteAccepted: boolean;
  isCurrentUserSupport: boolean;
  shopifyConnectIds: any[];
  teamOwner: IUserInfo;
  teamMembersCount: number;
  supplierId?: number;
  invitedUsers?: UserTeam[];
}

export interface IUserTeamMember extends IUserInfo {
  teams: Team[];
}

export enum CreationFlow {
  REGISTRATION = "registration",
  INVITE = "invite",
}

export enum RelatedEntityNames {
  USER = "User",
  TEAM = "Team",
}

export enum RelatedEntitySubjects {
  INVITER = "Inviter",
  TEAM = "Team",
  PROJECT = "Project",
  TV_PROJECT = "TV Project",
  BENCHMARK_PROJECT = "Benchmark Project",
}

export enum UserActivityLogTypes {
  TEAM_INVITE = "team-invite",
  PROJECT_INVITE = "project-invite",
  TV_PROJECT_INVITE = "tv-project-invite",
  BENCHMARK_PROJECT_INVITE = "benchmark-project-invite",
  EMAIL_CONFIRMATION = "email-confirmation",
}

export interface IUserActivityLogRelatedEntity {
  name: string;
  id: number;
  entityName: RelatedEntityNames;
  subject: RelatedEntitySubjects;
}

export interface ISendgridEvent {
  email: string;
  event: SendgridWebhookEvents;
  response: string;
  timestamp: number;
}

export interface IUserActivityLogMail {
  createdat: string;
  externalId: string;
  id: number;
  recipientId: number;
  senderId: number;
  updatedat: string;
  sendgridEvents: ISendgridEvent[];
}

export type UserActivityLog = {
  createdat: string;
  id: number;
  relatedEntities: IUserActivityLogRelatedEntity[];
  text: string;
  type: UserActivityLogTypes;
  updatedat: string;
  user: IUserInfo;
  userId: number;
  mailId?: number;
  mail?: IUserActivityLogMail;
};

export enum SendgridWebhookEvents {
  PROCESSED = "processed",
  DEFERRED = "deferred",
  DELIVERED = "delivered",
  OPEN = "open",
  CLICK = "click",
  BOUNCE = "bounce",
  DROPPED = "dropped",
  SPAMREPORT = "spamreport",
  UNSUBSCRIBE = "unsubscribe",
  GROUP_UNSUBSCRIBE = "group_unsubscribe",
  GROUP_RESUBSCRIBE = "group_resubscribe",
}

export interface IFile {
  url: string;
  mimetype: string;
}

export interface IUploadFileResponse {
  data: string;
  mimetype: string;
}

export interface IOrderFile extends IFile {
  title?: string;
  description?: string;
}

export interface IOrderFabric {
  fabric: string;
  weight: number;
}
export interface IUserUpcomingInvoice {
  totalPriceAfterDiscountEnd: number;
  percentOff?: number;
  amountOff?: number;
  discountEnd?: number;
  currentSubscriptionPrice: number;
  currentBalance: number;
  couponName?: string;
  amountDue: number;
}

export interface IProductOrderProposalSizeFabric {
  fabric: string;
  weight: number;
  price?: number;
}
export interface IProductOrderProposalSize {
  size: string;
  quantity: number;
  price?: number;
}

export enum ProductOrderProposalStatuses {
  ACTIVE = "active",
  ACCEPTED = "accepted",
  DECLINED = "declined",
  RETURNED = "returned",
}

export interface IProductOrderStatisticsItem {
  count: number;
  volume: number | null;
  units: number;
}

export interface IProductOrderGroupedStatisticsItem
  extends IProductOrderStatisticsItem {
  portalId?: number;
  portalName?: string;
  teamId?: number;
  teamName?: string;
  teamCompanyName?: string;
}

export interface IProductOrderStatistics {
  confirmedOrders: IProductOrderStatisticsItem;
  pendingOrders: IProductOrderStatisticsItem;
  completedOrders: IProductOrderStatisticsItem;
}

export interface IProductOrderGroupedStatistics {
  confirmedOrders: IProductOrderGroupedStatisticsItem[];
  pendingOrders: IProductOrderGroupedStatisticsItem[];
  completedOrders: IProductOrderGroupedStatisticsItem[];
}

export type ProductFilters = {
  material?: string;
  color?: string;
  brand?: string;
  retailer?: string;
  priceRange?: RangeType;
};

export enum AIDesignVairationType {
  ImageToImage = "ImageToImage",
  TextToImage = "TextToImage",
}

export interface IProposalPaymentPlan {
  title: string;
  payout?: number;
  amount: number;
}

export interface IPaymentTerm {
  title: string;
  payout?: number;
}

export interface IProductOrderProposalRevision {
  positionsTotalPrice: number;
  customTotalPrice: number | null;
  timestamp: string;
  positions: IProductOrderProposalSize[];
  paymentPlan: IProposalPaymentPlan[];
}

export enum ProductOrderTypes {
  SAMPLE = "sample",
  BULK = "bulk",
}

export interface IProductOrderDeliveryData {
  images: IFile[];
  trackingNumber: string;
  trackingUrl?: string;
  carrier: string;
}

export enum NotificationType {
  ORDER_PRODUCTION_TO_BRAND = "order-production-to-brand", //  Previously ORDER_PRODUCTION = 'order-production',
  ORDER_PRODUCTION_TO_SUPPLIER = "order-production-to-supplier",
  ORDER_SHIPPED_TO_BRAND = "order-shipped-to-brand", //  Previously ORDER_SHIPPED = 'order-shipped',
  ORDER_SHIPPED_TO_SUPPLIER = "order-shipped-to-supplier",
  ORDER_COMPLETED_TO_BRAND = "order-completed-to-brand",
  ORDER_COMPLETED_TO_SUPPLIER = "order-completed-to-supplier", //  Previously ORDER_COMPLETED = 'order-completed',
  PROPOSAL_CREATED_TO_BRAND = "proposal-created-to-brand", // Previously PROPOSAL_CREATED = 'proposal-created',
  PROPOSAL_CREATED_TO_SUPPLIER = "proposal-created-to-supplier",
  PROPOSAL_REVOKED_TO_BRAND = "proposal-revoked-to-brand", // Previously PROPOSAL_REVOKED = 'proposal-revoked',
  PROPOSAL_REVOKED_TO_SUPPLIER = "proposal-revoked-to-supplier",
  PROPOSAL_ACCEPTED_TO_BRAND = "proposal-accepted-to-brand",
  PROPOSAL_ACCEPTED_TO_SUPPLIER = "proposal-accepted-to-supplier", // Previously PROPOSAL_ACCEPTED = 'proposal-accepted',
  PROPOSAL_REJECTED_TO_BRAND = "proposal-declined-to-brand",
  PROPOSAL_REJECTED_TO_SUPPLIER = "proposal-declined-to-supplier", // Previously PROPOSAL_REJECTED = 'proposal-declined',
  PROPOSAL_RETURNED_TO_BRAND = "proposal-returned-to-brand",
  PROPOSAL_RETURNED_TO_SUPPLIER = "proposal-returned-to-supplier", // Previously PROPOSAL_RETURNED = 'proposal-returned',
  PROPOSAL_REVISED_TO_BRAND = "proposal-revised-to-brand",
  PROPOSAL_REVISED_TO_SUPPLIER = "proposal-revised-to-supplier",
}

export interface ICountryOption {
  name: string;
  code: string;
  value: string;
}

export interface ISupplierBasicFormValues {
  location: ICountryOption;
  service?: ISelectValue[];
  description: string;
  businessModel?: ISelectValue;
  monthlyOutput?: number;
  grossAnnualSalesCurrency?: ISelectValue;
  grossAnnualSales?: number;
  productionLeadTime?: number;
  moq?: number;
  shippingMethod?: ISelectValue[];
  rdStuffCount?: number;
  qaStuffCount?: number;
  employeeCount?: number;
}

export interface ISupplierPaymentValues {
  paymentTerms: IPaymentTerm[];
}

export interface ISupplierGalleryValues {
  productGallery: IFile[];
  factoryGallery: IFile[];
  certificationFiles: IFile[];
}

export interface ISupplierProductionValues {
  gender: ISelectValue[];
  material: string;
  country: ISelectValue[];
  productionNotes?: string;
  isPublicOrdersDisabled: boolean;
  workingHoursStart?: string;
  workingHoursEnd?: string;
  timeZone?: ITimezone;
}

export interface ISupplierFinancialAbilityValues {
  isFinancingAbility?: boolean;
  financingAbilityDescription?: string;
}

export interface IPortalFeaturesValues {
  isImageGenerationRestricted: boolean;
  isPortalSwitcherEnabled: boolean;
  minAiGenerationSimilarity: number;
  maxAiGenerationSimilarity: number;
  isWatermarkEnabled: boolean;
  customWatermarkData: {
    text: string;
    font_size: number;
    font_color: string;
    alpha: number;
    rotate: number;
  };
  isLiveHelpEnabled: boolean;
}

export interface ISupplierFormValues
  extends ISupplierBasicFormValues,
    ISupplierGalleryValues,
    ISupplierProductionValues,
    ISupplierFinancialAbilityValues,
    ISupplierPaymentValues {}

export enum AssetType {
  SampleImage = "sample-image",
  PatternImage = "pattern-image",
}

export interface IPortalLimits {
  aiProjects: number;
  aiProjectsGuests: number;
  channels: string[];
  guests: number | null;
  imageCredits: number;
  mediaLibraryAssets: number;
  monitoring: boolean;
  ownPortal: boolean;
  projects: number;
  subscriptionStatus: SubscriptionStatuses;
  teamMembers: number;
  teams: number;
  trendForecast: string;
  userId: number;
}

export interface IIpLocation {
  id: number;
  ip: string;
  location?: string;
  bigDataCloudLocation?: string;
  geoapifyLocation?: string;
  ipStackLocation?: string;
  bigDataCloudCountryISO?: string;
  geoapifyCountryISO?: string;
  ipStackCountryISO?: string;
  counter: number;
}

export type Subscription = Stripe.Subscription & {
  default_patment_method?: Stripe.PaymentMethod;
};

export enum SupplierStatuses {
  ACTIVE = "active",
  DISABLED = "disabled",
}

export interface IUserDebts {
  openInvoices: Array<{
    invoice: Stripe.Invoice;
  }>;
}
